<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {dateUtil} from "@/helpers/date-util";
import {maxLength, required} from "vuelidate/lib/validators";
import allCountries from "@/helpers/all-countries";
import {errorCatcher} from "@/helpers/error-catcher";
import {timeUtil} from "@/helpers/time-util";
import Swal from "sweetalert2";

/**
 * Allegro Component
 */
export default {
  computed: {
    timeUtil() {
      return timeUtil
    },

    paginationHelper() {
      return paginationHelper
    }
  },

  components: {
    Layout,
    PageHeader
  },

  data() {
    return {

      allegroAccountId: "",

      return_policy: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,

        elements: null,
        availabilityRestrictionCause: null,

        currentPolicy: {
          allegroAccountId: "",

          name: "",
          availabilityRange: "FULL",
          availabilityRestrictionCause: null,
          withdrawalPeriod: "",
          returnCostCoveredBy: "",
          addressName: "",
          addressStreet: "",
          addressPostCode: "",
          addressCity: "",
          addressCountryCode: "PL",
          addressCountry: "PL",
          phoneNumber: "",
          email: "",

          cashOnDeliveryNotAllowed: false,
          freeAccessoriesReturnRequired: false,
          refundLoweredByReceivedDiscount: false,
          abroadReturnTermsMayDiffer: false,
          businessReturnAllowed: false,
          collectBySellerOnly: false
        },

        modal: false
      },

      implied_warranty: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,

        elements: null,

        forCorporate: false,

        currentImpliedWarranty: {
          name: "",
          individual: "",
          addressName: "",
          addressStreet: "",
          addressPostCode: "",
          addressCity: "",
          addressCountryCode: "PL",
          addressCountry: "PL",
          description: ""
        },

        modal: false
      },

      warranty: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "id",
        sortDesc: false,

        elements: null,

        individual: 0,
        corporate: 0,

        otherThanCustomer: false,

        currentWarranty: {
          name: "",
          type: "MANUFACTURER",
          individual: 0,
          individualLifetime: false,
          corporate: 0,
          corporateLifetime: false,
          description: ""
        },

        modal: false
      },

      submitted: false,
      activeAccount: null,
    }
  },

  validations: {
    return_policy: {
      currentPolicy: {
        name: {required},
        availabilityRange: { required },
        returnCostCoveredBy: { required },
        addressName: { required },
        addressStreet: { required },
        addressPostCode: { required },
        addressCity: { required },
        addressCountryCode: { required },
        addressCountry: { required }
      }
    },

    implied_warranty: {
      currentImpliedWarranty: {
        name: { required },
        individual: { required },
        addressName: { required },
        addressStreet: { required },
        addressPostCode: { required },
        addressCity: { required },
        addressCountryCode: { required },
        addressCountry: { required },
        description: { maxLength: maxLength(10000) }
      }
    },

    warranty: {
      currentWarranty: {
        name: { required },
        type: { required },
        description: { maxLength: maxLength(10000) }
      }
    }
  },

  methods: {
    getAllCountries() {
      return allCountries;
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('after-sale-service.title'),
          active: true
        }
      ]
    },

    getReturnPolicyFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "availabilityRange", label: this.$t('after-sale-service.return-policy.table.availability-range') },
        {key: "withdrawalPeriod", label: this.$t('after-sale-service.return-policy.table.deadline') },
        {key: "returnCostCoveredBy", label: this.$t('after-sale-service.return-policy.table.return-cost') },
        {key: "address", label: this.$t('after-sale-service.return-policy.table.address') },
        {key: "phoneNumber", label: this.$t('after-sale-service.return-policy.table.phone-number') },
        {key: "email", label: 'Email' },
        {key: "createdAt", label: this.$t('after-sale-service.return-policy.table.created-at'), formatter: dateUtil.asDateTime },
        {key: "action", label: this.$t('table.actions')}
      ]
    },
    
    getWithdrawalPeriodMatch(item) {
      if (!item.withdrawalPeriod) {
        return this.$t('after-sale-service.return-policy.body.cannot-withdrawal')
      }

      return timeUtil.asHumanTime(item.withdrawalPeriod)
    },

    editReturnPolicy(returnPolicy) {
      this.return_policy.currentPolicy = Object.assign({}, returnPolicy);
      this.return_policy.modal = true;
    },

    hideReturnPolicyModal() {
      this.return_policy.modal = false;
      this.return_policy.currentPolicy = {
        allegroAccountId: "",

        name: "",
        availabilityRange: "FULL",
        availabilityRestrictionCause: null,
        withdrawalPeriod: "",
        returnCostCoveredBy: "",
        addressName: "",
        addressStreet: "",
        addressPostCode: "",
        addressCity: "",
        addressCountryCode: "PL",
        addressCountry: "PL",
        phoneNumber: "",
        email: "",

        cashOnDeliveryNotAllowed: false,
        freeAccessoriesReturnRequired: false,
        refundLoweredByReceivedDiscount: false,
        abroadReturnTermsMayDiffer: false,
        businessReturnAllowed: false,
        collectBySellerOnly: false
      };
    },

    createOrEditReturnPolicy() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.return_policy.currentPolicy.$touch();
      if (this.$v.return_policy.currentPolicy.$invalid) {
        return;
      }

      this.return_policy.currentPolicy.allegroAccountId = this.allegroAccountId

      const json = JSON.stringify(this.return_policy.currentPolicy);

      axios.put(`/allegro/return-policy/${this.allegroAccountId}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        this.$bvToast.toast(this.$t(this.return_policy.currentPolicy.id ? "after-sale-service.return-policy.edit.success" : "after-sale-service.return-policy.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.return_policy.currentPolicy.id) {
          for (const index in this.return_policy.elements) {
            if (this.return_policy.elements[index].id === this.return_policy.currentPolicy.id) {
              this.return_policy.elements[index] = this.return_policy.currentPolicy;
              break;
            }
          }
        } else {
          this.return_policy.elements.push(result.data)
        }

        this.$refs.return_policy.refresh()
        this.hideReturnPolicyModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    selectReturnPolicyAddressCountry(selected) {
      const countries = allCountries.filter(country => country.iso2 === selected);
      const country = countries[0];
      this.return_policy.currentPolicy.addressCountry = country.name;
    },

    async loadReturnPolicy() {
      let page = this.return_policy.currentPage - 1;
      if (page > 0) {
        page = this.return_policy.currentPage * this.return_policy.perPage - this.return_policy.perPage;
      }

      const result = await axios.get(`/allegro/return-policy/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
          "page": page,
          "size": this.return_policy.perPage
        }
      });

      this.return_policy.elements = result.data
      await this.loadReturnPolicyCount()

      if (this.mounted) {
        this.$refs.return_policy.refresh()
      }

      return this.return_policy.elements;
    },

    async loadReturnPolicyCount() {
      const result = await axios.get(`/allegro/return-policy/count`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
        }
      });

      this.return_policy.totalRows = result.data;
      this.return_policy.rows = result.data;
    },

    async loadAvailabilityRestrictionCause() {
      const result = await axios.get(`/allegro/return-policy/availability-restriction-cause`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {}
      });

      this.return_policy.availabilityRestrictionCause = result.data;
    },

    async loadImpliedWarranty() {
      let page = this.implied_warranty.currentPage - 1;
      if (page > 0) {
        page = this.implied_warranty.currentPage * this.implied_warranty.perPage - this.implied_warranty.perPage;
      }

      const result = await axios.get(`/allegro/implied-warranty/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
          "page": page,
          "size": this.implied_warranty.perPage
        }
      });

      this.implied_warranty.elements = result.data
      await this.loadImpliedWarrantyCount()

      if (this.mounted) {
        this.$refs.implied_warranty.refresh()
      }

      return this.implied_warranty.elements;
    },

    async loadImpliedWarrantyCount() {
      const result = await axios.get(`/allegro/implied-warranty/count`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
        }
      });

      this.implied_warranty.totalRows = result.data;
      this.implied_warranty.rows = result.data;
    },

    getImpliedWarrantyFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "individual", label: this.$t('after-sale-service.implied-warranty.table.individual') },
        {key: "corporate", label: this.$t('after-sale-service.implied-warranty.table.corporate') },
        {key: "address", label: this.$t('after-sale-service.return-policy.table.address') },
        {key: "createdAt", label: this.$t('after-sale-service.return-policy.table.created-at'), formatter: dateUtil.asDateTime },
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    editImpliedWarranty(impliedWarranty) {
      this.implied_warranty.currentImpliedWarranty = Object.assign({}, impliedWarranty);
      this.implied_warranty.forCorporate = false
      this.implied_warranty.modal = true;
    },

    hideImpliedWarrantyModal() {
      this.implied_warranty.modal = false;
      this.implied_warranty.currentImpliedWarranty = {
        name: "",
        individual: "",
        addressName: "",
        addressStreet: "",
        addressPostCode: "",
        addressCity: "",
        addressCountryCode: "PL",
        addressCountry: "PL",
        description: ""
      };
    },

    createOrEditImpliedWarranty() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.implied_warranty.currentImpliedWarranty.$touch();
      if (this.$v.implied_warranty.currentImpliedWarranty.$invalid) {
        return;
      }

      this.implied_warranty.currentImpliedWarranty.corporate = (!this.implied_warranty.forCorporate) ? this.implied_warranty.currentImpliedWarranty.individual : '';
      this.implied_warranty.currentImpliedWarranty.allegroAccountId = this.allegroAccountId;

      const json = JSON.stringify(this.implied_warranty.currentImpliedWarranty);

      axios.put(`/allegro/implied-warranty/${this.allegroAccountId}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        this.$bvToast.toast(this.$t(this.implied_warranty.currentImpliedWarranty.id ? "after-sale-service.implied-warranty.edit.success" : "after-sale-service.implied-warranty.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.implied_warranty.currentImpliedWarranty.id) {
          for (const index in this.implied_warranty.elements) {
            if (this.implied_warranty.elements[index].id === this.implied_warranty.currentImpliedWarranty.id) {
              this.implied_warranty.elements[index] = this.implied_warranty.currentImpliedWarranty;
              break;
            }
          }
        } else {
          this.implied_warranty.elements.push(result.data)
        }

        this.$refs.implied_warranty.refresh()
        this.hideImpliedWarrantyModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    async loadWarranty() {
      let page = this.warranty.currentPage - 1;
      if (page > 0) {
        page = this.warranty.currentPage * this.warranty.perPage - this.warranty.perPage;
      }

      const result = await axios.get(`/allegro/warranty/list/pagination`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
          "page": page,
          "size": this.warranty.perPage
        }
      });

      this.warranty.elements = result.data
      await this.loadWarrantyCount()

      if (this.mounted) {
        this.$refs.warranty.refresh()
      }

      return this.warranty.elements;
    },

    async loadWarrantyCount() {
      const result = await axios.get(`/allegro/warranty/count`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "allegroAccountId": this.allegroAccountId,
        }
      });

      this.warranty.totalRows = result.data;
      this.warranty.rows = result.data;
    },

    getWarrantyFields() {
      return [
        {key: "name", label: this.$t('message.name')},
        {key: "individual", label: this.$t('after-sale-service.implied-warranty.table.individual') },
        {key: "corporate", label: this.$t('after-sale-service.implied-warranty.table.corporate') },
        {key: "createdAt", label: this.$t('after-sale-service.return-policy.table.created-at'), formatter: dateUtil.asDateTime },
        {key: "action", label: this.$t('table.actions')}
      ]
    },

    editWarranty(warranty) {
      this.warranty.currentWarranty = Object.assign({}, warranty);

      if (this.warranty.currentWarranty.individual) {
        this.warranty.individual = this.warranty.currentWarranty.individual.match(/\d+/)[0];
      }

      if (this.warranty.currentWarranty.corporate) {
        this.warranty.corporate = this.warranty.currentWarranty.corporate.match(/\d+/)[0];
        this.warranty.otherThanCustomer = true;
      }

      this.warranty.modal = true;
    },

    hideWarrantyModal() {
      this.warranty.modal = false;
      this.warranty.currentWarranty = {
        name: "",
        type: "MANUFACTURER",
        individual: 0,
        individualLifetime: false,
        corporate: 0,
        corporateLifetime: false,
        description: ""
      };
    },

    createOrEditWarranty() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.warranty.currentWarranty.$touch();
      if (this.$v.warranty.currentWarranty.$invalid) {
        return;
      }

      this.warranty.currentWarranty.allegroAccountId = this.allegroAccountId

      if (!this.warranty.otherThanCustomer) {
        this.warranty.currentWarranty.corporateLifetime = this.warranty.currentWarranty.individualLifetime;
        this.warranty.currentWarranty.corporate = this.warranty.currentWarranty.individual;
      }

      const json = JSON.stringify(this.warranty.currentWarranty);

      axios.put(`/allegro/warranty/${this.allegroAccountId}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then((result) => {
        this.$bvToast.toast(this.$t(this.warranty.currentWarranty.id ? "after-sale-service.warranty.edit.success" : "after-sale-service.warranty.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        if (this.warranty.currentWarranty.id) {
          for (const index in this.warranty.elements) {
            if (this.warranty.elements[index].id === this.warranty.currentWarranty.id) {
              this.warranty.elements[index] = this.warranty.currentWarranty;
              break;
            }
          }
        } else {
          this.warranty.elements.push(result.data)
        }

        this.$refs.warranty.refresh()
        this.hideWarrantyModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    setWarrantyIndividual(value) {
      this.warranty.individual = value;
      this.warranty.currentWarranty.individual = "P" + value + "M";
    },

    setWarrantyCorporate(value) {
      this.warranty.corporate = value;
      this.warranty.currentWarranty.corporate = "P" + value + "M";
    },

    synchronize(url) {
      axios.post(`/${url}/sync/${this.allegroAccountId}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Synchronizacja przebiegła pomyślnie", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });

      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

  },

  async beforeMount() {
    this.allegroAccountId = this.$route.params.allegroAccountId;
    await this.loadReturnPolicy()
    await this.loadAvailabilityRestrictionCause()

    await this.loadImpliedWarranty()
    await this.loadWarranty()
  }

}

</script>

<template>
  <Layout>
    <PageHeader :items="getItems()" :title="$t('after-sale-service.title')"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t('after-sale-service.return-policy.title') }}</h5>

            <div>
              <a class="clickable-element btn btn-success mb-2 my-2" @click="return_policy.modal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('after-sale-service.return-policy.create.button') }}
              </a>

              <div class="float-right">
                <b-button variant="primary" @click="synchronize('allegro/return-policy')">Synchronizuj</b-button>
              </div>
            </div>

            <template v-if="return_policy.elements">
              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="return_policy.perPage" size="sm" :options="return_policy.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="return_policy.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                      ref="return_policy"
                      :items="loadReturnPolicy"
                      :fields="getReturnPolicyFields()"
                      responsive="sm"
                      :per-page="return_policy.perPage"
                      :current-page="return_policy.currentPage"
                      :sort-by.sync="return_policy.sortBy"
                      :sort-desc.sync="return_policy.sortDesc"
                      :filter="return_policy.filter"
                      :filter-included-fields="return_policy.filterOn"
                      @filtered="paginationHelper.onFiltered(return_policy)"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(availabilityRange)="{ item }">
                      <span> {{ $t(item.availabilityRange === 'FULL' ? 'message.yes' : 'after-sale-service.return-policy.restriction-cause.' + item.availabilityRestrictionCause + '.name') }}</span>
                    </template>

                    <template v-slot:cell(withdrawalPeriod)="{ item }">
                      <span>{{ getWithdrawalPeriodMatch(item) }}</span>
                    </template>

                    <template v-slot:cell(returnCostCoveredBy)="{ item }">
                      <span>{{ $t('after-sale-service.return-policy.body.return-cost-covered-by.' + item.returnCostCoveredBy) }}</span>
                    </template>

                    <template v-slot:cell(address)="{ item }">
                      <span>{{ item.addressName }} {{ item.addressStreet }} {{ item.addressPostCode }} {{ item.addressCity }} {{ item.addressCountryCode }}</span>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <a @click="editReturnPolicy(item)" class="clickable-element text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                    </template>

                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(return_policy), 'elements': paginationHelper.getElementsCount(return_policy), 'all': return_policy.totalRows }) }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="return_policy.currentPage" :total-rows="return_policy.rows" :per-page="return_policy.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t('after-sale-service.implied-warranty.title') }}</h5>

            <div>
              <a class="clickable-element btn btn-success mb-2 my-2" @click="implied_warranty.modal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('after-sale-service.implied-warranty.create.button') }}
              </a>

              <div class="float-right">
                <b-button variant="primary" @click="synchronize('allegro/implied-warranty')">Synchronizuj</b-button>
              </div>
            </div>

            <template v-if="implied_warranty.elements">
              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="implied_warranty.perPage" size="sm" :options="implied_warranty.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="implied_warranty.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table

                      ref="implied_warranty"
                      :items="loadImpliedWarranty"
                      :fields="getImpliedWarrantyFields()"
                      responsive="sm"
                      :per-page="implied_warranty.perPage"
                      :current-page="implied_warranty.currentPage"
                      :sort-by.sync="implied_warranty.sortBy"
                      :sort-desc.sync="implied_warranty.sortDesc"
                      :filter="implied_warranty.filter"
                      :filter-included-fields="implied_warranty.filterOn"
                      @filtered="paginationHelper.onFiltered(implied_warranty)"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(individual)="{ item }">
                      <span>{{ timeUtil.asHumanTime(item.individual) }}</span>
                    </template>

                    <template v-slot:cell(corporate)="{ item }">
                      <span>{{ timeUtil.asHumanTime(item.corporate) }}</span>
                    </template>

                    <template v-slot:cell(address)="{ item }">
                      <span>{{ item.addressName }} {{ item.addressStreet }} {{ item.addressPostCode }} {{ item.addressCity }} {{ item.addressCountryCode }}</span>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <a @click="editImpliedWarranty(item)" class="clickable-element text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                    </template>

                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(implied_warranty), 'elements': paginationHelper.getElementsCount(implied_warranty), 'all': implied_warranty.totalRows }) }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="implied_warranty.currentPage" :total-rows="implied_warranty.rows" :per-page="implied_warranty.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t('after-sale-service.warranty.title') }}</h5>

            <div>
              <a class="clickable-element btn btn-success mb-2 my-2" @click="warranty.modal = true">
                <i class="mdi mdi-plus mr-2"></i> {{ $t('after-sale-service.warranty.create.button') }}
              </a>

              <div class="float-right">
                <b-button variant="primary" @click="synchronize('allegro/warranty')">Synchronizuj</b-button>
              </div>
            </div>

            <template v-if="warranty.elements">
              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="warranty.perPage" size="sm" :options="warranty.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="warranty.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table

                      ref="warranty"
                      :items="loadWarranty"
                      :fields="getWarrantyFields()"
                      responsive="sm"
                      :per-page="warranty.perPage"
                      :current-page="warranty.currentPage"
                      :sort-by.sync="warranty.sortBy"
                      :sort-desc.sync="warranty.sortDesc"
                      :filter="warranty.filter"
                      :filter-included-fields="warranty.filterOn"
                      @filtered="paginationHelper.onFiltered(warranty)"
                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(individual)="{ item }">
                      <span>{{ item.individualLifetime ? $t('after-sale-service.warranty.modal.warranty-period.lifetime') : timeUtil.asHumanTime(item.individual) }}</span>
                    </template>

                    <template v-slot:cell(corporate)="{ item }">
                      <span>{{ item.corporateLifetime ? $t('after-sale-service.warranty.modal.warranty-period.lifetime') : timeUtil.asHumanTime(item.corporate) }}</span>
                    </template>

                    <template v-slot:cell(action)="{ item }">
                      <a @click="editWarranty(item)" class="clickable-element text-primary"><i class="mdi mdi-pencil font-size-18"></i></a>
                    </template>

                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(warranty), 'elements': paginationHelper.getElementsCount(warranty), 'all': warranty.totalRows }) }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="warranty.currentPage" :total-rows="warranty.rows" :per-page="warranty.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        v-model="return_policy.modal"
        :scrollable="true"
        :title="$t(this.return_policy.currentPolicy.id ? 'after-sale-service.return-policy.edit.title' : 'after-sale-service.return-policy.create.button')"
        hide-footer
        title-class="font-18"
        size="xl"
        @hide="hideReturnPolicyModal"
        @esc="hideReturnPolicyModal">
      <form>

        <div class="form-group">
          <label for="name">{{$t('after-sale-service.return-policy.modal.name')}}</label>
          <input id="name" v-model="return_policy.currentPolicy.name" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.name.$error }" />
          <div v-if="submitted && !$v.return_policy.currentPolicy.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <h5>{{  $t('after-sale-service.return-policy.modal.can-return.title') }}</h5>

          <div>
            <input type="radio" id="FULL" value="FULL" v-model="return_policy.currentPolicy.availabilityRange" name="availability">
            <label for="FULL" style="margin-left: 5px;">{{  $t('after-sale-service.return-policy.modal.can-return.full') }}</label>
          </div>
          <div>
            <input type="radio" id="RESTRICTED" value="RESTRICTED" v-model="return_policy.currentPolicy.availabilityRange" name="availability">
            <label for="RESTRICTED" style="margin-left: 5px;">{{ $t('after-sale-service.return-policy.modal.can-return.restricted') }}</label>
            <p style="font-size: 10px">{{  $t('after-sale-service.return-policy.modal.can-return.warning') }}</p>
          </div>
        </div>

        <div class="form-group" v-if="return_policy.currentPolicy.availabilityRange === 'RESTRICTED' && return_policy.availabilityRestrictionCause">
          <h5>{{  $t('after-sale-service.return-policy.modal.restricted.title') }}</h5>

          <div v-for="(restrictionCause, index) in return_policy.availabilityRestrictionCause" :key="index">
            <input type="radio" :id="restrictionCause" :value="restrictionCause" v-model="return_policy.currentPolicy.availabilityRestrictionCause" :name="restrictionCause">
            <label :for="restrictionCause" style="margin-left: 5px;">{{  $t('after-sale-service.return-policy.restriction-cause.' + restrictionCause + '.name') }}</label>
            <p>{{  $t('after-sale-service.return-policy.restriction-cause.' + restrictionCause + '.example') }}</p>
          </div>
        </div>

        <div class="form-group">
          <label for="withdrawalPeriod">{{$t('after-sale-service.return-policy.modal.deadline')}}</label>
          <input id="withdrawalPeriod" v-model="return_policy.currentPolicy.withdrawalPeriod" type="text" class="form-control" />
        </div>

        <div class="form-group">
          <label for="returnCost">{{$t('after-sale-service.return-policy.modal.return-cost')}}</label>
          <select v-model="return_policy.currentPolicy.returnCostCoveredBy" class="custom-select" id="type" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.returnCostCoveredBy.$error }">
            <option value="BUYER">{{ $t('after-sale-service.return-policy.body.return-cost-covered-by.BUYER')}}</option>
            <option value="SELLER">{{ $t('after-sale-service.return-policy.body.return-cost-covered-by.SELLER')}}</option>
          </select>
        </div>

        <h5>{{ $t('after-sale-service.return-policy.modal.address.title') }}</h5>

        <div class="form-group">
          <label for="addressName">{{$t('after-sale-service.return-policy.modal.address.name')}}</label>
          <input id="addressName" v-model="return_policy.currentPolicy.addressName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.addressName.$error }" />
          <div v-if="submitted && !$v.return_policy.currentPolicy.addressName.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressStreet">{{$t('after-sale-service.return-policy.modal.address.street-and-building-number')}}</label>
          <input id="addressStreet" v-model="return_policy.currentPolicy.addressStreet" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.addressStreet.$error }" />
          <div v-if="submitted && !$v.return_policy.currentPolicy.addressStreet.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressPostCode">{{$t('after-sale-service.return-policy.modal.address.postcode')}}</label>
          <input id="addressPostCode" v-model="return_policy.currentPolicy.addressPostCode" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.addressPostCode.$error }" />
          <div v-if="submitted && !$v.return_policy.currentPolicy.addressPostCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressCity">{{$t('after-sale-service.return-policy.modal.address.city')}}</label>
          <input id="addressCity" v-model="return_policy.currentPolicy.addressCity" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.addressCity.$error }" />
          <div v-if="submitted && !$v.return_policy.currentPolicy.addressCity.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressCountryCode">{{$t('after-sale-service.return-policy.modal.address.country')}}</label>
          <select v-model="return_policy.currentPolicy.addressCountryCode" @input="event => selectReturnPolicyAddressCountry(event.target.value)" class="custom-select" :class="{ 'is-invalid': submitted && $v.return_policy.currentPolicy.addressCountryCode.$error }">
            <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2.toUpperCase()}`" >{{ item.name }} - {{ item.iso2.toUpperCase() }}</option>
          </select>
          <div v-if="submitted && !$v.return_policy.currentPolicy.addressCountryCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <h5>{{ $t('after-sale-service.return-policy.modal.contact.title')}}</h5>

        <div class="form-group">
          <label for="phoneNumber">{{$t('after-sale-service.return-policy.modal.contact.phone-number')}}</label>
          <input id="phoneNumber" v-model="return_policy.currentPolicy.phoneNumber" type="text" class="form-control" />
        </div>

        <div class="form-group">
          <label for="email">{{$t('after-sale-service.return-policy.modal.contact.email')}}</label>
          <input id="email" v-model="return_policy.currentPolicy.email" type="text" class="form-control" />
        </div>

        <h5>{{ $t('after-sale-service.return-policy.modal.added-info.title') }}</h5>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="cash-on-delivery-not-allowed" v-model="return_policy.currentPolicy.cashOnDeliveryNotAllowed" type="checkbox" class="custom-control-input" />
            <label for="cash-on-delivery-not-allowed" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.cash-on-delivery-not-allowed') }}</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="free-accessories-return-required" v-model="return_policy.currentPolicy.freeAccessoriesReturnRequired" type="checkbox" class="custom-control-input" />
            <label for="free-accessories-return-required" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.free-accessories-return-required') }}</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="refund-lowered-by-received-discount" v-model="return_policy.currentPolicy.refundLoweredByReceivedDiscount" type="checkbox" class="custom-control-input" />
            <label for="refund-lowered-by-received-discount" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.refund-lowered-by-received-discount') }}</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="abroad-return-terms-may-differ" v-model="return_policy.currentPolicy.abroadReturnTermsMayDiffer" type="checkbox" class="custom-control-input" />
            <label for="abroad-return-terms-may-differ" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.abroad-return-terms-may-differ') }}</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="business-return-allowed" v-model="return_policy.currentPolicy.businessReturnAllowed" type="checkbox" class="custom-control-input" />
            <label for="business-return-allowed" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.business-return-allowed') }}</label>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="collect-by-seller-only" v-model="return_policy.currentPolicy.collectBySellerOnly" type="checkbox" class="custom-control-input" />
            <label for="collect-by-seller-only" class="custom-control-label">{{ $t('after-sale-service.return-policy.modal.added-info.collect-by-seller-only') }}</label>
          </div>
        </div>

        <div class="text-right">
          <b-button variant="success" @click="createOrEditReturnPolicy">{{ $t(this.return_policy.currentPolicy.id ? 'after-sale-service.return-policy.edit.button' : 'after-sale-service.return-policy.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideReturnPolicyModal">{{ $t('message.cancel') }}</b-button>
        </div>

      </form>
    </b-modal>

    <!-- Modal -->
    <b-modal
        id="modal-2"
        v-model="implied_warranty.modal"
        :scrollable="true"
        :title="$t(this.implied_warranty.currentImpliedWarranty.id ? 'after-sale-service.implied-warranty.edit.title' : 'after-sale-service.implied-warranty.create.button')"
        hide-footer
        title-class="font-18"
        size="xl"
        @hide="hideImpliedWarrantyModal"
        @esc="hideImpliedWarrantyModal">
      <form>

        <div class="form-group">
          <label for="name">{{$t('after-sale-service.implied-warranty.modal.name')}}</label>
          <input id="name" v-model="implied_warranty.currentImpliedWarranty.name" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.name.$error }" />
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="individual">{{$t('after-sale-service.implied-warranty.modal.complaint-period.title')}}</label>
          <select v-model="implied_warranty.currentImpliedWarranty.individual" class="custom-select" id="type" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.individual.$error }">
            <option value="P1Y">{{ $t('after-sale-service.implied-warranty.modal.complaint-period.time.1')}}</option>
            <option value="P2Y">{{ $t('after-sale-service.implied-warranty.modal.complaint-period.time.2')}}</option>
          </select>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input id="corporate" v-model="implied_warranty.forCorporate" type="checkbox" class="custom-control-input" />
            <label for="corporate" class="custom-control-label">{{ $t('after-sale-service.implied-warranty.modal.complaint-period.corporate') }}</label>
          </div>
        </div>

        <h5>{{ $t('after-sale-service.return-policy.modal.address.title') }}</h5>

        <div class="form-group">
          <label for="addressName">{{$t('after-sale-service.return-policy.modal.address.name')}}</label>
          <input id="addressName" v-model="implied_warranty.currentImpliedWarranty.addressName" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.addressName.$error }" />
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.addressName.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressStreet">{{$t('after-sale-service.return-policy.modal.address.street-and-building-number')}}</label>
          <input id="addressStreet" v-model="implied_warranty.currentImpliedWarranty.addressStreet" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.addressStreet.$error }" />
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.addressStreet.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressPostCode">{{$t('after-sale-service.return-policy.modal.address.postcode')}}</label>
          <input id="addressPostCode" v-model="implied_warranty.currentImpliedWarranty.addressPostCode" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.addressPostCode.$error }" />
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.addressPostCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressCity">{{$t('after-sale-service.return-policy.modal.address.city')}}</label>
          <input id="addressCity" v-model="implied_warranty.currentImpliedWarranty.addressCity" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.addressCity.$error }" />
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.addressCity.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <label for="addressCountryCode">{{$t('after-sale-service.return-policy.modal.address.country')}}</label>
          <select v-model="implied_warranty.currentImpliedWarranty.addressCountryCode" @input="event => selectImpliedWarrantyAddressCountry(event.target.value)" class="custom-select" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.addressCountryCode.$error }">
            <option v-for="(item, index) in getAllCountries()" :key="index" :value="`${item.iso2.toUpperCase()}`" >{{ item.name }} - {{ item.iso2.toUpperCase() }}</option>
          </select>
          <div v-if="submitted && !$v.implied_warranty.currentImpliedWarranty.addressCountryCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <textarea id="description" v-model="implied_warranty.currentImpliedWarranty.description"  class="form-control" type="text" :maxlength="10000" :class="{ 'is-invalid': submitted && $v.implied_warranty.currentImpliedWarranty.description.$error }"/>
          <p v-if="implied_warranty.currentImpliedWarranty.description" class="badge position-absolute" :class="{ 'badge-success': implied_warranty.currentImpliedWarranty.description.length !== 10000, 'badge-danger': implied_warranty.currentImpliedWarranty.description.length === 10000 }">
            {{ implied_warranty.currentImpliedWarranty.description.length }} /
            {{ 10000 }}
          </p>

          <p>{{ $t('after-sale-service.implied-warranty.modal.description') }}</p>
        </div>

        <div class="text-right">
          <b-button variant="success" @click="createOrEditImpliedWarranty">{{ $t(this.implied_warranty.currentImpliedWarranty.id ? 'after-sale-service.implied-warranty.edit.button' : 'after-sale-service.implied-warranty.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideImpliedWarrantyModal">{{ $t('message.cancel') }}</b-button>
        </div>

      </form>
    </b-modal>

    <!-- Modal -->
    <b-modal
        id="modal-3"
        v-model="warranty.modal"
        :scrollable="true"
        :title="$t(this.warranty.currentWarranty.id ? 'after-sale-service.warranty.edit.title' : 'after-sale-service.warranty.create.button')"
        hide-footer
        title-class="font-18"
        size="xl"
        @hide="hideWarrantyModal"
        @esc="hideWarrantyModal">
      <form>

        <div class="form-group">
          <label for="name">{{$t('after-sale-service.warranty.modal.name')}}</label>
          <input id="name" v-model="warranty.currentWarranty.name" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.warranty.currentWarranty.name.$error }" />
          <div v-if="submitted && !$v.warranty.currentWarranty.name.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <h5>{{  $t('after-sale-service.warranty.modal.type.title') }}</h5>

          <div>
            <input type="radio" id="MANUFACTURER" value="MANUFACTURER" v-model="warranty.currentWarranty.type" name="MANUFACTURER">
            <label for="MANUFACTURER" style="margin-left: 5px;">{{  $t('after-sale-service.warranty.modal.type.types.MANUFACTURER') }}</label>
          </div>

          <div>
            <input type="radio" id="SELLER" value="SELLER" v-model="warranty.currentWarranty.type" name="SELLER">
            <label for="SELLER" style="margin-left: 5px;">{{  $t('after-sale-service.warranty.modal.type.types.SELLER') }}</label>
          </div>

          <div v-if="submitted && !$v.warranty.currentWarranty.type.required" class="invalid-feedback">{{ $t('message.required')}}</div>
        </div>

        <div class="form-group">
          <h5>{{  $t('after-sale-service.warranty.modal.warranty-period.title') }}</h5>

          <div>
            <input type="radio" id="limited" value="limited" name="limited" :checked="warranty.currentWarranty.individualLifetime === false" @click="warranty.currentWarranty.individualLifetime = false">
            <label for="limited" style="margin-left: 5px;">{{  $t('after-sale-service.warranty.modal.warranty-period.limited') }}</label>
          </div>

          <template v-if="!warranty.currentWarranty.individualLifetime">
            <div class="form-group">
              <label for="month">{{$t('after-sale-service.warranty.modal.month')}}</label>
              <input id="month" :value="warranty.individual" @input="event => setWarrantyIndividual(event.target.value)" type="number" class="form-control" />
            </div>
          </template>

          <div>
            <input type="radio" id="lifetime" value="lifetime" name="lifetime" :checked="warranty.currentWarranty.individualLifetime === true" @click="warranty.currentWarranty.individualLifetime = true">
            <label for="lifetime" style="margin-left: 5px;">{{  $t('after-sale-service.warranty.modal.warranty-period.lifetime') }}</label>
          </div>
        </div>

        <div class="form-group">
          <h5>{{  $t('after-sale-service.warranty.modal.period-for-corporate.title') }}</h5>

          <div class="form-group">
            <div class="custom-control custom-checkbox mb-3">
              <input id="other-than-customer" v-model="warranty.otherThanCustomer" type="checkbox" class="custom-control-input" />
              <label for="other-than-customer" class="custom-control-label">{{ $t('after-sale-service.warranty.modal.period-for-corporate.other-than-customer') }}</label>
            </div>
          </div>

          <template v-if="warranty.otherThanCustomer">
            <div class="form-group">
              <label for="month">{{$t('after-sale-service.warranty.modal.month')}}</label>
              <input id="month" :value="warranty.corporate" @input="event => setWarrantyCorporate(event.target.value)" type="number" class="form-control" />
            </div>
          </template>
        </div>

        <div class="form-group">
          <textarea id="description" v-model="warranty.currentWarranty.description"  class="form-control" type="text" :maxlength="10000" :class="{ 'is-invalid': submitted && $v.warranty.currentWarranty.description.$error }"/>
          <p v-if="warranty.currentWarranty.description" class="badge position-absolute" :class="{ 'badge-success': warranty.currentWarranty.description.length !== 10000, 'badge-danger': warranty.currentWarranty.description.length === 10000 }">
            {{ warranty.currentWarranty.description.length }} /
            {{ 10000 }}
          </p>

          <p>{{ $t('after-sale-service.warranty.modal.description') }}</p>
        </div>

        <div class="text-right">
          <b-button variant="success" @click="createOrEditWarranty">{{ $t(this.warranty.currentWarranty.id ? 'after-sale-service.warranty.edit.button' : 'after-sale-service.warranty.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideWarrantyModal">{{ $t('message.cancel') }}</b-button>
        </div>

      </form>
    </b-modal>

  </Layout>
</template>